
export class ApiUrlService {

    GetApi() {
        var data = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'mode': 'no-cors',
            }
        }
        return data;
    }
     PostApi(body) {

        var data = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'mode': 'no-cors',

            },
            body: JSON.stringify(body)

        }
        return data;
    }
    PostApiImage(body) {
        var data = {
            method: 'Post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            }, body: body

        }
        return data;
    }
     DeleteApi(token) {
        var data = {
            method: 'Delete',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'mode': 'no-cors',
                'Authorization': 'Bearer '+token 
            },

        }
        return data;
    }
     PutApi(body,token) {
        var data = {
            method: 'Put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'mode': 'no-cors',
                'Authorization': 'Bearer '+token 
            },
            body: JSON.stringify(body)

        }
        return data;
    }
}