import React from "react"; 
import { Container, Row, Col } from "react-bootstrap";
import { contactConfig } from "../content_option"
import "bootstrap/dist/css/bootstrap.min.css";   
import {Header} from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { useState, useEffect } from "react";
import { ApiUrlService } from "../api-library/api-services";
import { Button } from "primereact/button";

export default function ContactUs() {

  const apiUrlService = new ApiUrlService()
  const [labels, setLabels] = useState([]);
  const [name, setName]= useState("");
  const [email, setEmail]= useState("");
  const [subject, setSubject]= useState("");
  const [message, setMessage]= useState("");
  
  const getLabels = () => {
    var service = apiUrlService.GetApi();
    fetch('https://api.oleapi.com/api/labels', service).then(res => res.json())
        .then(data => {
          setLabels(data.data)
        });
}

const sendMessage = async () => {
 

  const body = {
    subject : subject,
    message : message,
    name: name,
    email: email
  }
  var service = apiUrlService.PostApi(body);
  const response = await fetch('https://api.oleapi.com/api/support', service);
    const data = await response.json();
   
}

useEffect(() => {
  getLabels()
 
}, []);

  return (
    <div>
      <Container>
     
                 <Header title="My header" subtitle="subtitle2" />
        <Row className="mb-5 mt-3" style={{marginTop:"300px !important"}}>
          <Col lg="8">
            <h1 className="display-4 mb-4" style={{color:"black"}}>Contact Us</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp" style={{marginTop: "-100px"}}>
          <Col lg="5" className="mb-5">
            <h3 className="color_sec py-4" style={{color:'black',marginTop: "-30px"}}>Get in touch</h3>
            <address>
             <p style={{color:"black !important"}}><strong >Email:</strong></p>{" "}
              <a style={{color:"black !important"}} href={`mailto:${labels.email}`}>
                {labels.email}
              </a>
              <br />
              <br />
              {contactConfig.hasOwnProperty("YOUR_FONE") ? (
                <p style={{color:"#ec1f6a"}}>
                  <strong>Phone:</strong> {labels.phoneNumber}
                </p>
              ) : (
                ""
              )}
                <p style={{color:"#ec1f6a"}}><strong >Address:</strong>  {labels.addressEn}</p>{" "}
           
        
            
            </address>
            <p style={{color:"black"}}>{labels.description}</p>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <form  className="contact__form w-100">
              <Row>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name" 
                    type="text"
                    required 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
                <Col lg="6" className="form-group">
                  <input
                    className="form-control rounded-0"
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email" 
                    required 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
              </Row>
              <input
                    className="form-control rounded-0"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    required 
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
              <textarea
                className="form-control rounded-0"
                id="message"
                name="message"
                placeholder="Message"
                rows="5" 
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <br />
              <Row>
                <Col lg="12" className="form-group">
                <Button label="Send"  className="btn ac_btn" onClick={sendMessage} style={{backgroundColor:'black',color:"white",width:"20%"}}/>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        
      </Container>
      	      <Footer style={{marginTop:"10px !important"}}/>
              </div>
  );
}