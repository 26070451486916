import React, { useState, useEffect, useCallback } from "react";
import "./Header.css";
import logo from '../../assets/images/logo-white.svg';
import { Button } from 'primereact/button';
import { VirtualScroller } from 'primereact/virtualscroller';
import { classNames } from 'primereact/utils';
import car from '../../assets/images/car.png';
import {Routes, Route, useNavigate} from 'react-router-dom';
import Filter from '../filter/index.js'
import { Dropdown } from 'primereact/dropdown';
import { logout } from "../../actions/auth.js";
import { useDispatch } from "react-redux";
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';

import { SearchBar } from "../SearchBar.js";
import { SearchResultsList } from "../SearchResultsList.js";
import { ApiUrlService } from "../../api-library/api-services.js";





export const Header = (props) => {
    const [items] = useState(Array.from({ length: 7 }).map((_, i) => `Item #${i}`));
    const [results, setResults] = useState([]);
      const [selectedCity, setSelectedCity] = useState(0);
       const [selectedCat, setSelectedCat] = useState(0);
    const [cities, setCities] = useState([])
    const [category, setCategory] = useState([])
    const apiUrlService = new ApiUrlService()

    const getCities = () => {
      var service = apiUrlService.GetApi();
      fetch('https://api.oleapi.com/api/cities', service).then(res => res.json())
          .then(data => {
            setCities(data.data)
          });
  }

  const getCategories = () => {
    var service = apiUrlService.GetApi();
    fetch('https://api.oleapi.com/api/subCategories', service).then(res => res.json())
        .then(data => {
          setCategory(data.data)
        });
}


  useEffect(() => {
    getCities()
    getCategories()
   
}, [getCities]);


    const [selectedOptions, setSelectedOptions] = useState(null);
  const countries = [
    { name: 'View Profile', code: 'profile' },
    { name: 'View Ads', code: 'ads' },
    { name: 'View Favorites', code: 'fav' },
    { name: 'Logout', code: 'logout' },
  ];

  const onClickDropDown = async (e) => {
    setSelectedOptions(e.value);
    if(e.value.code == 'logout'){
      logOut();
    }
    if(e.value.code == 'profile'){
      navigate("/profile")
    }
  }
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`pi ${option.code.toLowerCase()}`}
            style={{ width: '18px' }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
      
        <div>{option.name}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">

          <span>
            <b>{selectedOptions && selectedOptions.name}</b>
          </span>
        
      </div>
    );
  };

    const dispatch = useDispatch();

    const logOut = useCallback(() => {
      dispatch(logout());
      navigate("/")
    }, [dispatch]);

    const fetchData = (value) =>{
      fetch("https://api.oleapi.com/api/ads")
      .then((response) => response.json())
      .then((json) => {
        const response = json.data.filter((ads) => {
          return value && ads && ads.title && ads.title.toLowerCase().includes(value);
        })
        console.log(response)
      })
    }

  
      const navigate = useNavigate();

  const navigateToLogin = () => {
    // 👇️ navigate to /contacts
    navigate('/login');
  };

  const user = JSON.parse(localStorage.getItem('user'));
 
     const itemTemplate = (item, options) => {
        const className = classNames('flex align-items-center p-2', {
            'surface-hover': options.odd
        });

        return (
            <div className={className} className="carDiv">
                   <img src={car}  alt="car" className="car"/>
                   <p >Vehicle</p>
            </div>
        );
    };

  return (
  
     <div className="header">
      <img src={logo}  alt="logo" className="logoHeader"/>
      
            <Button label="Post your AD" rounded className="button-22" />
     {user == null ? <Button label="Login" rounded className="button-22" onClick={navigateToLogin} /> : 
      <Dropdown className="button-23"
      icon={<ChevronDownIcon style={{ color: 'red' }} />}
      value={selectedOptions}
      onChange={(e) => onClickDropDown(e)}
      options={countries}
      optionLabel="name"
      placeholder={<i className="pi pi-user" style={{ color: 'slateblue' }}></i>}
      valueTemplate={selectedCountryTemplate}
      itemTemplate={countryOptionTemplate}
      panelFooterTemplate={panelFooterTemplate}
      dropdownIcon={(opts) => {
        return opts.iconProps['data-pr-overlay-visible'] ? (
          <ChevronRightIcon {...opts.iconProps} />
        ) : (
          <ChevronDownIcon {...opts.iconProps} />
        );
      }}
    />}
        <div  style={{ marginTop:"1.5%", display:"flex", flexDirection:"row", marginLeft:"2%"}}>
          <div>
          <SearchBar setResults={setResults} setCity={selectedCity != 0 ? selectedCity.id : 0} setCategory={selectedCat != 0 ? selectedCat.id : 0} />
        {results && results.length > 0 && <SearchResultsList results={results} />}
                </div>
            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" style={{height:"35px", width:"10px",marginLeft:"5px"}}
                showClear placeholder="City" className="md:w-10rem"  dropdownIcon={(opts) => {
                  return opts.iconProps['data-pr-overlay-visible'] ? (
                    <ChevronRightIcon {...opts.iconProps} />
                  ) : (
                    <ChevronDownIcon {...opts.iconProps} />
                  );
                }}/>
                 <Dropdown value={selectedCat} onChange={(e) => setSelectedCat(e.value)} options={category} optionLabel="nameEn" style={{height:"35px",marginLeft:"5px"}}
                showClear placeholder="Category" className="md:w-10rem" />
        </div>
       
				   
					

						
 
     

    
    </div>
  );
}