/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Card, Container, Row, Col } from "reactstrap";
import { Header } from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { ApiUrlService } from "../api-library/api-services";

export default function Profile () {

  const [customer, setCustomer] = useState();
  const apiUrlService = new ApiUrlService()
  

   const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    var service = apiUrlService.GetApi();
    fetch('https://api.oleapi.com/api/userByPhone?phone='+user.email, service).then(res => res.json())
        .then(data => {
          setCustomer(data.data)
        });
}


useEffect(() => {
  getUser()
 
 
}, [getUser]);


    return (
      <>
    <div>
    <Header title="My header" subtitle="subtitle2" />
  
        <main className="profile-page">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300">
                <div className="px-4">
                  <Row className="justify-content-center">
                  <Col className="order-lg-1" lg="4">
                  <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            style={{width:"150px", height:"150px", borderRadius:"75px"}}
                            className="rounded-circle"
                            src={customer&&customer.image}
                          />
                        </a>
                      </div>
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                        
                        </div>
                    
                      </div>
                    </Col>
                    <Col className="order-lg-2" lg="3">
                     
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="button-24"
                          
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                         Edit Profile
                        </Button>
                        <Button
                          className="button-24"
                          
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                          View Ads
                        </Button>
                        <Button
                          className="button-24"
                          label="Post your AD"
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                           View Favs
                        </Button>
                      </div>
                    </Col>
                    
                  </Row>
                  <div>
                    <h3>
                      {customer&&customer.firstname}{" "}{customer&&customer.lastname}
                 
                    </h3>
                    <div className="h6 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {customer&&customer.phone}
                    </div>
                    <div className="h6 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      {customer&&customer.email}
                    </div>
                    
                  </div>
                  <div className="mt-5 py-5 border-top text-center">
                    <Row className="justify-content-center">
                      <Col lg="9">
                  
                      <div class="listing-panel">
				<div class="title" style={{alignSelf:"center"}}></div>
				
			</div>
          
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
         
        </main>
        <Footer style={{width:"100vh !important"}}/>
        </div>
      </>
    );
  }


