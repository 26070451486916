import React, { useState, useEffect } from 'react';
import "./Main.css";
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Header} from "../header/Header";
import Footer from "../footer/Footer";
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { classNames } from 'primereact/utils';
import car from '../../assets/images/car.png';
import { VirtualScroller } from 'primereact/virtualscroller';
import 'primeicons/primeicons.css';
import { FaHeart } from "react-icons/fa";
import { ApiUrlService } from '../../api-library/api-services';

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '140px',
  arrows:false
}

 var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    width:'50%',
    arrows:true,
    centerMode:true
  };
  







export default function Main(props) {

	const [products, setProducts] = useState([]);
  
  const [items] = useState(Array.from({ length: 7 }).map((_, i) => `Item #${i}`));

  const navigate = useNavigate();

  const navigateToLogin = () => {
    // 👇️ navigate to /contacts
    navigate('/login');
  };

  const navigateToItem = (item) => {
    // 👇️ navigate to /contacts

    navigate('/item',  {
      state: {
        id: item,
      }
    });
  };
	const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case  'Beirut, Lebanon':
                return '';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };


     const itemTemplate = (item, options) => {
        const className = classNames('flex align-items-center p-2', {
            'surface-hover': options.odd
        });

        return (
            <div className="carDiv">
                   <img src={car}  alt="car" className="car"/>
            </div>
        );
    };

    const apiUrlService = new ApiUrlService()
    const [ProductService, setProductService] = useState([])

    const getCarsForSale = async () => {
      var service = apiUrlService.GetApi();
      fetch('https://api.oleapi.com/api/adsBySubCategory?subCategoryId=3', service).then(res => res.json())
          .then(data => {
            setProducts(data.data.slice(0, 9))
          });
    }

    const [slideImages, setSlideImages] = useState([])

    const getSlider = async () => {
      var service = apiUrlService.GetApi();
      fetch('https://api.oleapi.com/api/categorySlider', service).then(res => res.json())
          .then(data => {
     
            setSlideImages(data.data)
          });
    }

    const getVillaForSale = async () => {
      var service = apiUrlService.GetApi();
      fetch('https://api.oleapi.com/api/adsBySubCategory?subCategoryId=4', service).then(res => res.json())
          .then(data => {
            setProductService(data.data.slice(0, 9))
          });
    }

    const [categories, setCategories] = useState([]);
    const getCategories = async () => {
      var service = apiUrlService.GetApi();
      fetch('https://api.oleapi.com/api/categories', service).then(res => res.json())
          .then(data => {
            setCategories(data.data)
          });
    }

    useEffect(() => {
      const user = JSON.parse(localStorage.getItem('user'));
      getSlider();
      getCarsForSale();
      getVillaForSale();
      getCategories();
     
    }, []);
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 4,
            numScroll: 4
        },
        {
            breakpoint: '991px',
            numVisible: 4,
            numScroll: 4
        },
        {
            breakpoint: '767px',
            numVisible: 4,
            numScroll: 4
        }
    ];
   const productTemplate = (product) => {
        return (
      
            <div  onClick={() => navigateToItem(product.id)} className="m-2 text-center py-5 px-3">
                <div className="mb-3" style={{width:"300px"}}>
                    <img src={`${product.adImages[0] && product.adImages[0].image}`} alt={product.title} className="w-30 h-20 shadow-2" style={{margin:"0px 0px 0px 0px", marginLeft:"20px",position:"relative",width:'100%',marginTop:"-29px"}} />
                </div>
                <div style={{ position:"relative",backgroundColor:'#F0f0f0',height:'147px',borderBottomRightRadius:'20px',borderBottomLeftRadius:'20px',marginTop: '210px',width: '300px',
    left: '20px',
    marginTop: '202px'}}>
      <div style={{marginTop:'-29px'}}>
                    <h4 className="mb-1" style={{paddingTop: '1rem',color:'black',textAlign:'left',paddingLeft:'18px'}}>{product.title}</h4>
                              <i className="pi pi-map-marker" style={{ color: '#ec1f6a' ,width:15,fontSize: '1.3rem',textAlign:'left',marginLeft:"-200px",marginTop:"5px"}}></i>
       
                    <Tag style={{backgroundColor:'transparent',fontSize:'16px',textAlign:'left'}} value={product && product.city.name} severity={getSeverity(product)}></Tag>
                       <div style={{borderWidth:1,width:'80%',  marginLeft: "29px",color:"#e5e5e5",marginTop:"5px"}}></div>
                   
                              
                    <div className="flex flex-wrap gap-2 justify-content-center" style={{marginTop:"20px"}}>
                       <h3 style={{marginTop:'7px',color:'#ec1f6a',marginLeft:"7px",fontWeight:"700"}}>${product.price}</h3>
                        <Button style={{ backgroundColor: '#e5e5e5',marginLeft:'80px',color:'#aaaaaa'}} icon="pi pi-heart-fill" rounded />
                        <Button style={{ backgroundColor: '#e5e5e5',paddingLeft:"10px",color:'#aaaaaa'}} icon="pi pi-phone" rounded severity="success" />
                    </div>
                    </div>
                </div>
            </div>
            
        );
    };

  return (
	         <div
               
            >
                 <Header title="My header" subtitle="subtitle2" />
    <div className="main">
         <div className="flex">
            <VirtualScroller items={categories} itemSize={90} itemTemplate={itemTemplate} orientation="horizontal"  style={{ width: '90%', height: '240px', overflow: "hidden", marginTop: '-25px' }} />
        </div>		
       <div className="slide-container" style={{
    marginTop: "-17px"
}}>
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.image})` }}>
         
              </div>
            </div>
          ))} 
        </Slide>
          <h2 style={{color:"black",textAlign:'left',marginLeft:'75px',marginTop:'-70px'}}>Featured Cars for Sale</h2>
		  <div className="card">
            <Carousel value={products} numVisible={4} numScroll={4} responsiveOptions={responsiveOptions} itemTemplate={productTemplate}  style={{marginTop:"-18px", marginLeft:"-5px"}}/>
        </div>

        <h2 style={{color:"black",textAlign:'left',marginLeft:'75px',marginTop:"-90px"}}>Featured Villas for Sale</h2>

         <div className="card">
            <Carousel value={ProductService} numVisible={4} numScroll={4} responsiveOptions={responsiveOptions} itemTemplate={productTemplate}  style={{marginTop:"-18px", marginLeft:"-5px"}}/>
        </div>
      </div>

      <div class="footer-banner">
	<a href="#!"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs8RHY9hYUzWJoBaE_7EQ8wSGE7e80S6V6XQ&amp;usqp=CAU"/> </a>
</div>

	      <Footer />
    </div>
	</div>
  );
}
