import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { contactConfig } from "../content_option"
import "bootstrap/dist/css/bootstrap.min.css";   
import {Header} from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { ApiUrlService } from '../api-library/api-services';

export default function AboutUs() {
  
  const apiUrlService = new ApiUrlService()
   const [text, setText] = useState([]);


  const getAboutUs = () => {
        var service = apiUrlService.GetApi();
        fetch('https://api.oleapi.com/api/aboutUs', service).then(res => res.json())
            .then(data => {
              setText(data.data)
            });
    }


    useEffect(() => {
        getAboutUs()
       
    }, [getAboutUs]);

  return (
    <div>
      <Container>
     
                 <Header title="My header" subtitle="subtitle2" />
        <Row className="mb-5 mt-3" style={{marginTop:"100px !important",textAlign:"center"}}>
        
            <h1 className="display-4 mb-2" style={{color:"black",textAlign:"center"}}>About Us</h1>
           
       
        </Row>
        

          <div><img src={text.image} style={{width:1050, height:250}} alt="Logo" className="bannerAbout" /></div>
        <div><p style={{color:"black",textAlign: "left", textJustify: "inter-word",whiteSpace:'pre-wrap', lineHeight: 3}}>{text.textEn}</p></div>
        
      </Container>
      	      <Footer style={{marginTop:"10px !important"}}/>
              </div>
  );
}