import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { InputText } from "primereact/inputtext";

export const SearchBar = ({ setResults, setCity, setCategory }) => {
  const [input, setInput] = useState("");

  const fetchData = (value) => {

    fetch("https://api.oleapi.com/api/globalSearch?city="+setCity+"&subCategoryId="+setCategory)
      .then((response) => response.json())
      .then((json) => {
        const results = json.data.filter((ads) => {
          return (
            value &&
            ads &&
            ads.title &&
            ads.title.toLowerCase().includes(value)
          );
        });
        console.log(results)
        setResults(results);
      });
  };

  const handleChange = (value) => {
    setInput(value);
    fetchData(value);
  };

  return (
    <div className="input-wrapper">
      <span>
                    <i className="pi pi-search" style={{marginTop:"10px",position:'relative', left:"25px",color:"#ec1f6a"}} />
                    <InputText placeholder="Search"
                    value={input}
                    onChange={(e) => handleChange(e.target.value) }
                     style={{textAlign:"left",paddingLeft:"40px",height:"35px",width:"370px",color:"black"}} />
                </span>
    </div>
  );
};