import Headerr from "../components/Header"
import Signup from "../components/Signup";
import {Header} from "../components/header/Header";
import Footer from "../components/footer/Footer";
export default function SignupPage(){
    return(
        <>
                  <Header title="My header" subtitle="subtitle2" />
                <div style={{ width:"100%", height:"70vh"}}>
        <div style={{ backgroundColor: "white", width:"60%", margin:"auto", height:"30%",   position: "relative",top: "120px", paddingBottom:"50px"}}>
            <Headerr
              heading="Signup to create an account"
              paragraph="Already have an account? "
              linkName="Login"
              linkUrl="/login"
            />
            <Signup/>
            </div>
            </div>
                        <Footer/>  
        </>
    )
}