export default function FormExtra(){
    return(
        <div className="flex items-center justify-between " style={{width:"60%",margin:"auto", marginTop:"30px"}}>
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
             style={{ left:"50%"}}
            className="h-4 w-4 text-black-600 focus:ring-black-500 border-gray-300 rounded"
          />
          <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900" style={{width:"119px",marginTop:'10px'}}>
            Remember me
          </label>
        </div>

        <div className="text-sm">
          <a href="#" className="font-medium text-black-600 hover:text-black-500"  style={{marginRight:"10px"}}>
            Forgot your password?
          </a>
        </div>
      </div>

    )
}