import React, { useState } from 'react'
//import the css here
import '../style.css';
import logo from '../assets/images/54996.png';

export const PopChat = ( props ) => {
  let hide = {
    display: 'none',
  }
  let show = {
    display: 'block'
  }
  let textRef = React.createRef()
  const messages = ['hey','hi']

  const [chatopen, setChatopen] = useState(false)
  const toggle = e => {
    setChatopen(!chatopen)
  }


const handleSend = e => {
  const get = props.getMessage
  get(textRef.current.value)
}

  return (
    <div id='chatCon'>
      <div class="chat-box" style={chatopen ? show : hide}>
    <div class="header">Chat</div>
    <div class="msg-area">
      {
        messages.map((msg, i) => (
          i%2 ? (
          <p class="right"><span>{ msg }</span></p>
          ) : (
            <p class="left" style={{marginTop:'150px'}}><span>{ msg }</span></p>
          )
        ))
      }

    </div>
    <div class="footer">
      <input type="text"  ref={textRef} />
      <button onClick={handleSend}><i class="pi pi-send"></i></button>
    </div>
  </div>
    <div class="pop">
      <p><img onClick={toggle}  src={logo} alt="" /></p>
    </div>
    </div>
  )
}

export default PopChat