

import {Header} from "../components/header/Header";
import Footer from "../components/footer/Footer"
import Item from "../components/item/Item"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import '../components/item/Item';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'primereact/button';
import PopChat from '../pages/PopChat'
import { ApiUrlService } from "../api-library/api-services";

export default function ItemPage(){

    const location = useLocation();
    const [product, setProduct] = useState()
    const [customer, setCustomer] = useState()
    const [images, setImages] = useState([])

    const apiUrlService = new ApiUrlService()

    const getMessage =(msg) =>{
        console.log(msg)
      }

      const getItem = async () => {
    
        var service = apiUrlService.GetApi();
        fetch('https://api.oleapi.com/api/ads/'+location.state.id, service).then(res => res.json())
            .then(data => {
                setProduct(data.data)
            });
      }
      
  
      const getCustomer = async () => {
    
        var service = apiUrlService.GetApi();
        fetch('https://api.oleapi.com/api/adReportByAds?id='+location.state.id, service).then(res => res.json())
            .then(data => {
                setCustomer(data.data[0].user)
            });
      }

      const getImages = async () => {
    
        var service = apiUrlService.GetApi();
        fetch('https://api.oleapi.com/api/adImagesByAds?ads='+location.state.id, service).then(res => res.json())
            .then(data => {
                setImages(data.data)
            });
      }

    useEffect(() => {
        getItem()
        getCustomer()
        getImages();
      }, []);

  return (
	         <div
                style={{
                    minHeight: "500px",
                    color: "white",
                }}
            >
                 <Header title="My header" subtitle="subtitle2" />
                 <div style={{marginTop:'130px'}}>
			<div class="container bootdey">
                <h3 style={{color:"black",marginLeft:"15px"}}>{product && product.title}</h3>
<div class="col-md-12">
<section class="panel">
      <div class="panel-body">
          <div class="col-md-6">
              <div class="pro-img-details">
                  <img src={images && images[0] && images[0].image} alt=""/>
              </div>
              <div class="pro-img-list">
                  {images && images[1] && images[1].image && <a href="#/">
                      <img src={images && images[1] && images[1].image} alt=""/>
                  </a>}
                  {images && images[1] && images[1].image &&<a href="#/">
                      <img src={images && images[2] && images[2].image} alt=""/>
                  </a>}
                  {images && images[1] && images[1].image &&<a href="#/">
                      <img src={images && images[3] && images[3].image} alt=""/>
                  </a>}
                 
              </div>
              <div>
                 <h3 class="pro-d-subtitle">
             
                      Description
             
              </h3>
              <p style={{color:"black",marginLeft:"-20px"}}>
               {product && product.description} 
                </p>
                </div>
          </div>
          <div class="col-md-6" style={{left:"160px",marginTop:"10px"}}>
                         <h3 class="pro-d-title">
             
                      Seller Details
             
              </h3>
            <div class="user-profile">
                <div class="image">
                <img src={customer && customer.image} alt=""/>
                </div>
 
 <div>
              <h4 style={{color:"black"}}>
                 {customer && customer.firstname} {customer && customer.lastname} <span style={{color:"#ec1f6a"}}>(Verified)</span>
              </h4>
            
              <h5>
                <a>
                View Profile
                </a>
                </h5>
                  </div>
                </div>
              <div class="product_meta">
                  <span class="posted_in"><Button icon="pi pi-phone" rounded style={{padding:"1px",backgroundColor:"#ec1f6a", color:"white",width:"300px", justifyContent:"center",alignSelf:"center"}}>        {customer && customer.phone}</Button></span>
                   <h5 >
                <a>
                Chat with Seller
                </a>
                </h5>
              </div>
              <div class="m-bot25"> <strong>PRICE </strong></div>
               <div class="pro-price"> $ {product && product.price}</div>
             <div class="listing-panel">
				<div class="title">share ad</div>
				<div class="share-btns">
					<button><i class="pi pi-facebook"></i></button>
					<button><i class="pi pi-telegram"></i></button>
					<button><i class="pi pi-whatsapp"></i></button>
					<button><i class="pi pi-heart-fill"></i></button>
				</div>
			</div>
          </div>
      </div>
  </section>
{/*   <PopChat getMessage={getMessage}/> */}
  </div>
  </div>
		</div>
                 <Footer/>
                 </div>
  )
            }