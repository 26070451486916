import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import LoginPage from './pages/Login';
import {Header} from "./components/header/Header";
import Main from "./components/main/Main";
import Footer from "./components/footer/Footer";
import SignupPage from './pages/Signup';
import ItemPage from './pages/Item';
import ContactUs from './pages/ContactUs'
import AboutUs from './pages/AboutUs'
import PopChat from './pages/PopChat'
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";


import { useDispatch, useSelector } from "react-redux";
import {  Link, useLocation } from "react-router-dom";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeflex/primeflex.css';                                   // css utility
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';                       // core css
import './style.css';
import './flags.css';

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

export default function App() {

  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

/*   useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }
  }, [currentUser]); */

  return (
  

   
        <Routes>
            <Route path="/" element={<Main/>} />
             <Route path="/login" element={<LoginPage/>} />
              <Route path="/signup" element={<SignupPage/>} />
              <Route path="/item" element={<ItemPage/>} />
              <Route path="/contact" element={<ContactUs/>} />
              <Route path="/chat" element={<PopChat/>} />
              <Route path="/about" element={<AboutUs/>} />
              <Route path="/privacy" element={<PrivacyPolicy/>} />
              <Route path="/profile" element={<Profile/>} />
        </Routes>

  );
}
