import Headerr from "../components/Header"
import Login from "../components/Login"
import {Header} from "../components/header/Header";
import Footer from "../components/footer/Footer";

export default function LoginPage(){
    return(
        <div>
                <Header title="My header" subtitle="subtitle2" />
        <div style={{ width:"100%", height:"70vh", }}>
        <div style={{ width:"60%", margin:"auto", height:"110%",   position: "relative",top: "130px", paddingBottom:"100px", borderRadius:"25px"}}>
             <Headerr
                heading="Login to your account"
                paragraph="Don't have an account yet? "
                linkName="Signup"
                linkUrl="/signup"
                />
            <Login/>

           
            </div>
             	   
            </div>
            <div class="footer-banner" style={{marginTop:"-70px"}}>
	<a href="#!"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs8RHY9hYUzWJoBaE_7EQ8wSGE7e80S6V6XQ&amp;usqp=CAU"/> </a>
</div>
                    <Footer/>    
   </div>
    )
}