import {Link} from 'react-router-dom';
import logo from '../assets/images/logo-white.svg';
import {Routes, Route, useNavigate} from 'react-router-dom';

export default function Headerr({
    heading,
    paragraph,
    linkName,
    linkUrl="#"
}){

     const navigate = useNavigate();

  const navigateToLogin = () => {
    // 👇️ navigate to /contacts
    navigate('/');
  };

    return(
        <div className="mb-10">
        
            <h2 className="text-center text-3xl font-extrabold text-gray-900" style={{marginTop: "-1rem !important"}}>
                {heading}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 mt-5">
            {paragraph} {' '}
            <Link to={linkUrl} className="font-medium text-black-600 hover:text-black-500">
                {linkName}
            </Link>
            </p>
        </div>
    )
}