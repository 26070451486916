import React, {useState} from "react";
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";
import "./Footer.css";
import logo from '../../assets/images/logo-white.svg';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { ApiUrlService } from "../../api-library/api-services";
 
const Footer = () => {
   const [value, setValue] = useState('');
  const navigate = useNavigate();
  const apiUrlService = new ApiUrlService()

  const subscribe = async () => {

    const body = {
        email : value
    }
    var service = apiUrlService.PostApi(body);
    const response = await fetch('https://api.oleapi.com/api/subscriber', service);
    const data = await response.json();
    setValue('')
  }

  const navigateToLogin = () => {
    // 👇️ navigate to /contacts
    navigate('/contact');
  };

    const navigateToItem = () => {
    // 👇️ navigate to /contacts
    navigate('/chat');
  };


    return (
        <Box  style={{
                    marginTop: "20px",
                }}>
            <h1
                style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "10px",
                }}
            >
            </h1>
            <FooterContainer style={{width:"100%"}}>
                <Row>
                    <Column>
                        <Heading className="first">About OLE</Heading>
                        <FooterLink href="#" style={{color: "white !important"}}>
                            Directory
                        </FooterLink>
                        <FooterLink href="/about" style={{color: "white !important"}}>
                            About OLE
                        </FooterLink>
                        <FooterLink href="#" onClick={navigateToLogin} style={{color: "white !important"}}>
                            Contact Us
                        </FooterLink>
                         <FooterLink href="#" onClick={navigateToItem} style={{color: "white !important"}}>
                            Feedback
                        </FooterLink>
                    </Column>
                    <Column className="second">
                        <Heading>LEGAL</Heading>
                        <FooterLink href="#">
                            Terms and conditions
                        </FooterLink>
                        <FooterLink href="/privacy">
                            Privacy Policy
                        </FooterLink>
                        
                    </Column>
                    <Column className="center">
                        <img src={logo}  alt="logo" className="logo"/>
                        <p>
                           A community where underused goods are redistributed to fill new need, and become wanted again, where none-prodcut assets such as space
                        </p>
                        <p className="copyRight">© OLE 2023, All Rights Reserved</p>
                     
                    </Column>
                    <Column className="news">
                       
                         <div className="justify-content-center">
                           <Heading>SUBSCRIBE TO OUR NEWSLETTER</Heading>

        </div>
                    <InputText className="p-inputtext-lg" placeholder="Email Address" value={value} onChange={(e) => setValue(e.target.value)} style={{color:"black", paddingLeft:"10px"}} />
                       <Button label="Subscribe" rounded className="button-22" onClick={subscribe} />
 <h2>DOWNLOAD OLE APP</h2>
                       <div className="d-flex">
				<div className="me-3">
					<a href="#!">
						<img src="https://oleweb.s3.amazonaws.com/playstore.png"/>
					</a>
				</div>
				<div>
					<a href="#!">
						<img src="https://oleweb.s3.amazonaws.com/appstore.png"/>
					</a>
				</div>
			</div>
                    </Column>
                </Row>
            </FooterContainer>
        </Box>
    );
};
export default Footer;