import "./SearchResult.css";

export const SearchResult = ({ result }) => {
  return (
    <div
      className="search-result" style={{justifyContent:"space-between", flex:"row"}}
      onClick={(e) => alert(`You selected ${result}!`)}
    >
      <h5>{result.title}  <span style={{color:"#ec1f6a"}}>in {result.city.name}</span></h5>

     
    </div>
  );
};