import { useState } from 'react';
import { loginFields } from "../constants/formFields";
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import { ApiUrlService } from '../api-library/api-services';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';



const fields=loginFields;
let fieldsState = {};
fields.forEach(field=>fieldsState[field.id]='');

export default function Login(){
    const [loginState,setLoginState]=useState(fieldsState);
    const dispatch = useDispatch();
    const apiUrlService = new ApiUrlService()
    const handleChange=(e)=>{
        setLoginState({...loginState,[e.target.id]:e.target.value})
    }

    const [message, setMessage] = useState("")
    const navigate = useNavigate();

    const handleSubmit=(e)=>{
        e.preventDefault();
        authenticateUser();
      
    }

    //Handle Login API Integration here
    const authenticateUser = async () =>{
      const body = {
        }
  var service = apiUrlService.PostApi(body);
  const response = await fetch(`https://api.oleapi.com/api/login?email=${loginState.email}&password=${loginState.password}`, service);
    const data = await response.json();
  
     if (data.access_token) {
         localStorage.setItem("user", JSON.stringify(data));
        
         navigate("/");
    
       }else{
            setMessage("Invalid Credentials!")
       }

    

    }

    return(
        <form className="mt-3 space-y-6" onSubmit={handleSubmit}>
        <div className="-space-y-px">
            {
                fields.map(field=>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                    />
                
                )
            }
        </div>

        <FormExtra/>
        <FormAction handleSubmit={handleSubmit} text="LOGIN"/>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}

      </form>
    )
}